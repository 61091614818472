import type { CSSProperties, FunctionComponent } from 'react'
import type { Icons } from '../../../design-tokens/iconography/icons'

import clsx from 'clsx'
import { useState } from 'react'

import Icon from '../../../atoms/icon'
import Button, { ButtonSize } from '../../../molecules/button'
import Table, { TableProps } from '../index'

import styles from './custom.cell.module.scss'

interface CellWithIconProps {
  name?: string,
  href?: string,
  column?: any,
  options?: any,
  icon?: Icons | string
  className?: string,
  expand?: {
    name: string,
    table: TableProps
  }
}

const CellWithIcon: FunctionComponent<CellWithIconProps> = (
  {
    name,
    href,
    column,
    options = {},
    icon,
    className,
    expand
  }
) => {
  const ElementTag = href ? 'a' : 'div'
  const width = options.iconSize ? options.iconSize / 10 + 'rem' : '2.4rem'
  const [active, setActive] = useState(false)

  return (
    <ElementTag
      href={href}
      className={clsx(
        styles.customCell,
        styles.customCellWithIcon,
        options.expand && expand && styles.customCellWithExpand,
        className
      )}
    >
      {
        icon
        && <Icon
          name={icon}
          style={{ '--icon-size': width } as CSSProperties}
        />
      }
      {name}
      {
        options.expand
        && expand
        && <>
          <Button
            className={styles.customExpandButton}
            size={ButtonSize.small}
            iconAfter={active ? 'chevron-up' : 'chevron-down'}
            onClick={(e) => {
              e.preventDefault()
              setActive(!active)
            }}
          >
            {expand.name}
          </Button>
          <Table
            className={clsx(
              styles.customExpandTable,
              active && styles.customExpandTableActive
            )}
            {...expand.table}
          />
        </>
      }
    </ElementTag>
  )
}

export type { CellWithIconProps }
export default CellWithIcon
