import clsx from 'clsx'
import React, { FunctionComponent, PropsWithChildren } from 'react'

import Caption from '../../atoms/caption'
import Icon from '../../atoms/icon'
import Title from '../../atoms/title'
import Image, { ImageLayout } from '../../molecules/image'
import Pill, { PillColor, PillSize } from '../../molecules/pill'

import styles from './accordion-item.module.scss'

interface AccordionGroupItemProps extends PropsWithChildren {
  label?: string
  content?: any
  time?: string
  groupAbbreviation?: string
  active?: boolean
}

const AccordionGroupItem: FunctionComponent<AccordionGroupItemProps> = (
  {
    time,
    groupAbbreviation,
    label,
    content,
    active

  }) => {

  return (
    <div className={styles.group}>
      <Pill size={PillSize.Medium} className={styles.title}>{groupAbbreviation}</Pill>

      <div className={styles.data}>
        <Title text={time} className={styles.distance}/>
        <Caption className={styles.status}>{label}</Caption>
      </div>

      <div className={styles.cyclist}>
        {!active && label !== 'peloton' && content?.componentProps?.data?.slice(0, 4).map((item: any, index:number) => {
            return item.name?.icon
              ? <Icon name={item.name?.icon}
                      className={clsx(styles.jersey, styles.jerseyIcon)}
                      key={item.name?.name + index}
              />
              : <Image layout={ImageLayout.Square}
                       key={item.name?.name + index}
                       src={item.name?.imageUrl}
                       className={clsx(
                         styles.jersey,
                         styles.jerseyImage)
                       }/>
          }
        )}
        {!active && label !== 'peloton' && content?.componentProps?.data?.length >= 5 &&
          <Pill className={clsx(
            styles.jersey,
            styles.jerseyImageMore
          )}>
            +{content?.componentProps?.data?.length - 4}
          </Pill>
        }
        {label === 'peloton' && content?.componentProps?.data?.length > 0 &&
          <Pill className={styles.pelotonCount} color={PillColor.Mute} size={PillSize.Medium}>
            {content?.componentProps?.data?.length} renners
          </Pill>
        }
      </div>
    </div>
  )
}

export default AccordionGroupItem
