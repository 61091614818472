import type { FunctionComponent } from 'react'

import clsx from 'clsx'
import { useState } from 'react'
import { ReactSVG } from 'react-svg'
import scrollIntoView from 'scroll-into-view-if-needed'

import Icon from '../../atoms/icon'
import { Icons } from '../../design-tokens/iconography/icons'
import Image from '../image'

import styles from './event-set.module.scss'

enum EventSetType {
  Home = 'HOME',
  Away = 'AWAY',
  Start = 'START',
  End = 'END'
}

interface EventSetItemProps {
  text?: string
  icon: Icons
  type: EventSetType | string
  timestamp?: number
  offset: number
}

interface EventSetProps {
  progress?: number
  image?: string
  svg?: string
  items?: EventSetItemProps[]
  isExtra?: boolean
}

const eventStyles: {
  [Key in EventSetType]: string
} = {
  'HOME': styles.home,
  'AWAY': styles.away,
  'START': styles.start,
  'END': styles.end
}

const scrollToElement = (e: any, selector: string) => {
  e.preventDefault()

  const element = document.querySelector(selector)

  if (element){
    VRT?.sporza?.Timeline?.updateIsExpanded(true)

    scrollIntoView(element, {
      behavior: 'smooth',
      block: 'center'
    })
  }
}

const EventSet: FunctionComponent<EventSetProps> = ({
  progress= 0,
  items,
  isExtra = false,
  image,
  svg
}) => {
  const [hover, setHover] = useState('')
  const [progressState, setProgressState] = useState(progress)

  const addInteraction = (svg: any) => {
    const lines = [...svg.querySelectorAll('#road--vertical--lines line')]
    let activeLine = lines[0]

    if (0 < progress && progress < 100) {
      lines.forEach((item: any, index: number) => {
        const line = item as SVGLineElement
        const nextProgress = (index + 2) / (lines.length + 1) * 100
        const currentProgress = (index + 1) / (lines.length + 1) * 100

        if (currentProgress <= progress && progress < nextProgress) {
          activeLine = line
          setProgressState(currentProgress) // snap progress to line
        }
      })

      activeLine.style.stroke = 'var(--color-ui-coal)'
    }

    const path = svg.querySelector('#road clipPath rect')
    switch (true) {
      case path && progressState === 100:
        path.style.width = '100%'
        break
      case path && 0 < progressState:
        path.style.width = activeLine.getAttribute('x1')
        break
    }

    const indicator = svg.querySelector('#live--indicator--dot')
    switch (true) {
      case indicator && 0 < progress && progress < 100:
        indicator.style.display = 'block'
        indicator.setAttribute('cx', activeLine.getAttribute('x1'))
        indicator.setAttribute('cy', activeLine.getAttribute('y1'))
        break
      case !!indicator:
        indicator.style.display = 'none'
    }

    const points = [...svg.querySelectorAll('#elements > g')]
    if (points){
      points.forEach((item: any) => {
        item.addEventListener('mouseover', (e: any) => {
          item.querySelector(`#${item.id}--default`)?.setAttribute('visibility', 'hidden')
          item.querySelector(`#${item.id}--pressed`)?.setAttribute('visibility', 'visible')
          if (item.querySelector(`#${item.id}--text`)) {
            item.querySelector(`#${item.id}--text`).style.fill = 'var(--color-ui-coal)'
            item.querySelector(`#${item.id}--text`).style.transform = 'translate(-10px,-70px)'
            item.querySelector(`#${item.id}--text`).setAttribute('visibility', 'visible')
          }
        })

        item.addEventListener('mouseout', () => {
          item.querySelector(`#${item.id}--default`)?.setAttribute('visibility', 'visible')
          item.querySelector(`#${item.id}--pressed`)?.setAttribute('visibility', 'hidden')
          item.querySelector(`#${item.id}--text`).setAttribute('visibility', 'hidden')
        })
      })
    }
  }

  return <div className={clsx(
    styles.eventSet,
    isExtra ? styles.extra : null
  )}>
    {!svg && image && <Image src={image} noCrop={true} priority={true} className={styles.image} />}
    {svg && <ReactSVG
      src={svg}
      className={styles.svg}
      afterInjection={(svg) => addInteraction(svg)}
    />}
    <ol className={styles.list}
    style={{ background: `linear-gradient(90deg,
      var(--event-set-color) ${progressState}%,
      var(--color-brand-tertiary-500) ${progressState}%)`
    }}>
      {items && items?.length > 0 && items.map( ({ type, offset, text, icon, timestamp }, index) =>
        <li
          className={clsx(
            styles.item,
            hover === `${index}${timestamp}` ? styles.active : undefined,
            eventStyles[type as EventSetType]
          )}
          key={`${index}-${offset}`}
          style={{ left: `${offset}%` }}>
          {text && <span className={styles.hoverLabel}>{text}</span>}
          <button
            onClick={(e) => timestamp && scrollToElement(e, `#timeline-${timestamp}`)}
            onMouseOver={() => setHover(`${index}${timestamp}`)}
            onMouseOut={() => setHover('')}
            className={clsx(
              styles.icon,
              timestamp ? styles.hover : undefined
            )}>
            <Icon name={icon}/>
          </button>
          <span className={styles.line}></span>
        </li>)}
    </ol>
  </div>
}

export default EventSet

export type {
  EventSetProps
}
