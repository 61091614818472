import type { ClassValue } from 'clsx'
import type { FunctionComponent } from 'react'
import type {
  ScoreboardDistanceProps,
  ScoreboardEventDistanceProps,
  ScoreboardEventRoundsProps,
  ScoreboardEventScoreProps,
  ScoreboardEventSetsProps,
  ScoreboardRoundsProps,
  ScoreboardScoreProps,
  ScoreboardSetsProps
} from './layout'
import type { UseApiProps } from '@sporza/hooks'

import { useApi } from '@sporza/hooks'
import clsx from 'clsx'

import { SportTypes, StatusTypes } from '../../molecules/score'

import { ScoreboardContext, ScoreboardLayoutTypes } from './config'
import {
  BannerDistanceBoard,
  BannerRoundsBoard,
  BannerScoreBoard,
  BannerSetsBoard,
  DefaultDistanceBoard,
  DefaultRoundsBoard,
  DefaultScoreBoard,
  DefaultSetsBoard,
  DetailedDistanceBoard,
  DetailedRoundsBoard,
  DetailedScoreBoard,
  DetailedSetsBoard,
  EventScoreBoard,
  SimpleDistanceBoard,
  SimpleRoundsBoard,
  SimpleScoreBoard,
  SimpleSetsBoard
} from './layout'
import styles from './scoreboard.module.scss'

interface ScoreboardComponentProps {
  componentProps: ScoreboardProps
}

interface ScoreboardProps extends UseApiProps, ScoreboardScoreProps, ScoreboardSetsProps, ScoreboardDistanceProps, ScoreboardRoundsProps, ScoreboardEventScoreProps, ScoreboardEventSetsProps, ScoreboardEventDistanceProps, ScoreboardEventRoundsProps {
  sport: SportTypes | string
  layout?: ScoreboardLayoutTypes
  label?: string
  status?: StatusTypes | string
  url?: string
  target?: string
  ariaLabel?: string
  showLogos?: boolean
  showLinks?: boolean
  showHeader?: boolean
  className?: ClassValue
  isLastItem?: boolean
  darkMode?: boolean
  hideCompetitionName?: boolean
  left?: string
  right?: string,
  designSystemBaseUrl?: string
  context?: ScoreboardContext
}

const Layout: FunctionComponent<ScoreboardProps> = (
  {
    sport,
    layout = ScoreboardLayoutTypes.Default,
    status,
    title,
    label,
    date,
    competitionName,
    competition,
    winner,
    winners,
    eventSets,
    ruler,
    meta,
    goals,
    groups,
    intermediates,
    relatedMatch,
    channels,

    distance,
    distanceToGo,
    stage,

    rounds,
    roundsToGo,

    type,
    typeIcon,
    weatherIcon,
    temperature,

    startLabel,
    endLabel,
    location,

    score,

    sets,

    home,
    away,

    player,

    showLogos,
    showLinks,
    showHeader = true,
    darkMode,
    isLastItem,
    hideCompetitionName,
    left,
    right,
    designSystemBaseUrl = 'https://design-system.sporza.be',
    context
  }
) => {
  const common = {
    sport,
    competitionName: hideCompetitionName ? undefined : competitionName,
    status,
    label,
    showHeader,
    layout,
    isLastItem,
    left,
    right,
    designSystemBaseUrl,
    context
  }

  layout = Object.values(ScoreboardLayoutTypes).includes(layout) ? layout : ScoreboardLayoutTypes.Default

  const scoreboardLayout: { [Key in SportTypes as string]: { [Key in ScoreboardLayoutTypes]: JSX.Element } } = {
    soccer: {
      default:
        <DefaultScoreBoard {...common}
                           score={score}
                           home={home}
                           away={away}
                           showLogos={showLogos}
                           showLinks={showLinks}
                           channels={channels}
        />,
      simple:
        <SimpleScoreBoard {...common}
                          score={score}
                          home={home}
                          away={away}
                          layout={layout}
        />,
      detailed:
        <DetailedScoreBoard {...common}
                            score={score}
                            home={home}
                            away={away}
                            eventSets={eventSets}
                            meta={meta}
                            goals={goals}
                            relatedMatch={relatedMatch}
                            showLogos={showLogos}
                            showLinks={showLinks}
                            channels={channels}
        />,
      compact:
        <DefaultScoreBoard {...common}
                           score={score}
                           home={home}
                           away={away}
                           showLogos={showLogos}
                           showLinks={showLinks}
                           channels={channels}
        />,
      event:
        <EventScoreBoard {...common}
                         title={title}
                         score={score}
                         home={home}
                         away={away}
                         player={player}
                         showLogos={showLogos}
                         showLinks={showLinks}
                         channels={channels}
        />,
      banner:
        <BannerScoreBoard {...common}
                          score={score}
                          home={home}
                          away={away}
                          title={title}
                          date={date}
                          showLogos={showLogos}
                          showLinks={showLinks}
                          darkMode={darkMode}
                          channels={channels}
        />
    },
    hockey: {
      default: <DefaultScoreBoard {...common}
                                  score={score}
                                  home={home}
                                  away={away}/>,
      simple: <SimpleScoreBoard {...common}
                                score={score}
                                home={home}
                                away={away}/>,
      detailed: <DetailedScoreBoard {...common}
                                    score={score}
                                    home={home}
                                    away={away}/>,
      compact: <DefaultScoreBoard {...common}
                                  score={score}
                                  home={home}
                                  away={away}/>,
      event: <></>,
      banner: <BannerScoreBoard {...common}
                                score={score}
                                home={home}
                                away={away}
                                title={title}
                                date={date}
                                showLogos={showLogos}
                                showLinks={showLinks}
                                darkMode={darkMode}
      />
    },
    handball: {
      default: <DefaultScoreBoard {...common}
                                  score={score}
                                  home={home}
                                  away={away}/>,
      simple: <SimpleScoreBoard {...common}
                                score={score}
                                home={home}
                                away={away}/>,
      detailed: <DetailedScoreBoard {...common}
                                    score={score}
                                    home={home}
                                    away={away}/>,
      compact: <DefaultScoreBoard {...common}
                                  score={score}
                                  home={home}
                                  away={away}/>,
      event: <></>,
      banner: <BannerScoreBoard {...common}
                                score={score}
                                home={home}
                                away={away}
                                title={title}
                                date={date}
                                showLogos={showLogos}
                                showLinks={showLinks}
                                darkMode={darkMode}
      />
    },
    tennis: {
      default: <DefaultSetsBoard {...common}
                                 sets={sets}
                                 home={home}
                                 away={away}
                                 showLinks={showLinks}/>,
      simple: <SimpleSetsBoard {...common}
                               sets={sets}
                               home={home}
                               away={away}/>,
      detailed: <DetailedSetsBoard {...common}
                                   sets={sets}
                                   home={home}
                                   away={away}
                                   showLinks={showLinks}/>,
      compact: <DefaultSetsBoard {...common}
                                 sets={sets}
                                 home={home}
                                 away={away}
                                 showLinks={showLinks}/>,
      event: <></>,
      banner: <BannerSetsBoard {...common}
                               date={date}
                               title={title}
                               sets={sets}
                               home={home}
                               away={away}
                               darkMode={darkMode}/>
    },
    basketball: {
      default: <DefaultSetsBoard {...common}
                                 sets={sets}
                                 home={home}
                                 away={away}/>,
      simple: <SimpleSetsBoard {...common}
                               sets={sets}
                               home={home}
                               away={away}/>,
      detailed: <DetailedSetsBoard {...common}
                                   sets={sets}
                                   home={home}
                                   away={away}/>,
      compact: <DefaultSetsBoard {...common}
                                 sets={sets}
                                 home={home}
                                 away={away}/>,
      event: <></>,
      banner: <BannerSetsBoard {...common}
                               date={date}
                               title={title}
                               sets={sets}
                               home={home}
                               away={away}
                               darkMode={darkMode}/>
    },
    volleyball: {
      default: <DefaultSetsBoard {...common}
                                 sets={sets}
                                 home={home}
                                 away={away}/>,
      simple: <SimpleSetsBoard {...common}
                               sets={sets}
                               home={home}
                               away={away}/>,
      detailed: <DetailedSetsBoard {...common}
                                   sets={sets}
                                   home={home}
                                   away={away}/>,
      compact: <DefaultSetsBoard {...common}
                                 sets={sets}
                                 home={home}
                                 away={away}/>,
      event: <></>,
      banner: <BannerSetsBoard {...common}
                               date={date}
                               title={title}
                               sets={sets}
                               home={home}
                               away={away}
                               darkMode={darkMode}/>
    },
    cycling: {
      default: distance
        ? <DefaultDistanceBoard {...common}
                                temperature={temperature}
                                weatherIcon={weatherIcon}
                                type={type}
                                typeIcon={typeIcon}
                                distance={distance}
                                distanceToGo={distanceToGo}
                                startLabel={startLabel}
                                endLabel={endLabel}
                                winner={winner}
                                showLinks={showLinks}/>
        : <DefaultRoundsBoard {...common}
                              rounds={rounds}
                              roundsToGo={roundsToGo}
                              startLabel={startLabel}
                              winner={winner}
                              showLinks={showLinks}
                              competition={competition}
                              type={type}
                              typeIcon={typeIcon}
                              weatherIcon={weatherIcon}
                              temperature={temperature}/>,
      simple: distance
        ? <SimpleDistanceBoard  {...common}
                                temperature={temperature}
                                weatherIcon={weatherIcon}
                                type={type}
                                typeIcon={typeIcon}
                                distance={distance}
                                distanceToGo={distanceToGo}
                                startLabel={startLabel}
                                endLabel={endLabel}
                                winner={winner}
                                showLinks={showLinks}/>
        : <SimpleRoundsBoard {...common}
                             rounds={rounds}
                             roundsToGo={roundsToGo}
                             startLabel={startLabel}
                             winner={winner}
                             showLinks={showLinks}/>,
      detailed: distance
        ? <DetailedDistanceBoard    {...common}
                                    temperature={temperature}
                                    weatherIcon={weatherIcon}
                                    type={type}
                                    typeIcon={typeIcon}
                                    distance={distance}
                                    distanceToGo={distanceToGo}
                                    startLabel={startLabel}
                                    endLabel={endLabel}
                                    winner={winner}
                                    showLinks={showLinks}
                                    stage={stage}
                                    eventSets={eventSets}
                                    groups={groups}
                                    intermediates={intermediates}
                                    ruler={ruler}/>
        : <DetailedRoundsBoard {...common}
                               rounds={rounds}
                               roundsToGo={roundsToGo}
                               startLabel={startLabel}
                               winner={winner}
                               showLinks={showLinks}
                               eventSets={eventSets}
                               ruler={ruler}
                               competition={competition}
                               type={type}
                               typeIcon={typeIcon}
                               weatherIcon={weatherIcon}
                               temperature={temperature}
                               groups={groups}
                               intermediates={intermediates}/>,
      compact: distance
        ? <DefaultDistanceBoard {...common}
                                temperature={temperature}
                                weatherIcon={weatherIcon}
                                type={type}
                                typeIcon={typeIcon}
                                distance={distance}
                                distanceToGo={distanceToGo}
                                startLabel={startLabel}
                                endLabel={endLabel}
                                winner={winner}
                                showLinks={showLinks}/>
        : <DefaultRoundsBoard {...common}
                              temperature={temperature}
                              weatherIcon={weatherIcon}
                              type={type}
                              typeIcon={typeIcon}
                              rounds={rounds}
                              roundsToGo={roundsToGo}
                              startLabel={startLabel}
                              winner={winner}
                              showLinks={showLinks}/>,
      event: <></>,
      banner: distance
        ? <BannerDistanceBoard {...common}
                               temperature={temperature}
                               weatherIcon={weatherIcon}
                               type={type}
                               title={title}
                               date={date}
                               typeIcon={typeIcon}
                               distance={distance}
                               distanceToGo={distanceToGo}
                               startLabel={startLabel}
                               endLabel={endLabel}
                               winners={winners}
                               darkMode={darkMode}/>
        : <BannerRoundsBoard {...common}
                             title={title}
                             date={date}
                             rounds={rounds}
                             roundsToGo={roundsToGo}
                             startLabel={startLabel}
                             winner={winner}
                             showLinks={showLinks}
                             darkMode={darkMode}/>,
    },
    formula1: {
      default: <DefaultRoundsBoard {...common}
                                   rounds={rounds}
                                   roundsToGo={roundsToGo}
                                   location={location}
                                   winner={winner}
                                   showLinks={showLinks}/>,
      simple: <SimpleRoundsBoard {...common}
                                 rounds={rounds}
                                 roundsToGo={roundsToGo}
                                 winner={winner}
                                 showLinks={showLinks}/>,
      detailed: <DetailedRoundsBoard {...common}
                                     rounds={rounds}
                                     roundsToGo={roundsToGo}
                                     location={location}
                                     winner={winner}
                                     showLinks={showLinks}
                                     eventSets={eventSets}
                                     ruler={ruler}/>,
      compact: <DefaultRoundsBoard {...common}
                                   rounds={rounds}
                                   roundsToGo={roundsToGo}
                                   location={location}
                                   winner={winner}
                                   showLinks={showLinks}/>,
      event: <></>,
      banner: <BannerRoundsBoard {...common}
                                 date={date}
                                 rounds={rounds}
                                 roundsToGo={roundsToGo}
                                 location={location}
                                 winner={winner}
                                 showLinks={showLinks}
                                 darkMode={darkMode}/>,
    }
  }

  return scoreboardLayout[sport]
    ? scoreboardLayout[sport as SportTypes][layout as ScoreboardLayoutTypes]
    : null
}

const Scoreboard: FunctionComponent<ScoreboardProps> = (props) => {
  const { data } = useApi(
    props,
    {
      keyPrefix: 'scoreboard'
    }
  )

  // TODO: fix this in SP-9280
  if (props.eventSets?.[0]?.image && data.eventSets?.length) {
    data.eventSets[0].image = props.eventSets?.[0]?.image
  }

  const {
    competitionName,
    layout,
    className,
    url,
    target,
    ariaLabel,
    darkMode,
    showLinks,
    showHeader,
    hideCompetitionName,
    context
  } = props
  const Tag = url && props.layout !== ScoreboardLayoutTypes.Detailed && props.layout !== ScoreboardLayoutTypes.Simple ? 'a' : 'div'

  // TODO: create a common scoreboard wrapper to move the tag logic inside
  return <Tag
    href={url ? url : undefined}
    aria-live={ariaLabel ? 'polite' : undefined}
    aria-label={ariaLabel}
    target={target && url ? target : undefined}
    className={clsx(
      styles.scoreboard,
      layout && styles[layout],
      data.status === StatusTypes.Live && styles.live,
      className,
      darkMode ? styles.darkMode : undefined,
    )}
  >
    <Layout
      {...{ ...data, showLinks: showLinks || !url, showHeader }}
      competitionName={competitionName}
      hideCompetitionName={hideCompetitionName}
      layout={layout}
      darkMode={darkMode}
      context={context}
    />
  </Tag>
}

export default Scoreboard

export {
  ScoreboardLayoutTypes
}

export type {
  ScoreboardProps,
  ScoreboardComponentProps
}
