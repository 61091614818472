import type { Icons } from '../../design-tokens/iconography/icons'

import clsx from 'clsx'
import { FunctionComponent } from 'react'

import Title, { TitleSize } from '../../atoms/title'
import Button, { ButtonProps, ButtonSize, ButtonVariant } from '../../molecules/button'

import styles from './link-list.module.scss'

enum LinkListDirection {
  Horizontal = 'Horizontal',
  Vertical = 'Vertical'
}

enum LinkListLayout {
  Default = 'default',
  Grey = 'Grey'
}

interface LinkListComponentProps {
  componentProps: LinkListProps
}

interface LinkListProps {
  items?: ButtonProps[]
  layout?: LinkListLayout | string
  title?: string
  subtitle?: any
  direction?: LinkListDirection | string
  buttonSize?: ButtonSize | string
  darkMode?: boolean
  variant?: ButtonVariant | string
  iconAfter?: Icons | string
  className?: string
}
// VERDER: link list grey ipv team
const LinkList: FunctionComponent<LinkListProps> = ({
  items,
  layout,
  title,
  subtitle,
  direction= LinkListDirection.Vertical,
  buttonSize = ButtonSize.large,
  darkMode= false,
  variant = ButtonVariant.list,
  iconAfter = 'chevron-right',
  className

}) => {

  return (
    <div className={clsx(
      className,
      styles.linkListWrapper,
      direction === LinkListDirection.Horizontal && styles.linkListHorizontal,
      layout === LinkListLayout.Grey && styles.linkListGrey,
      darkMode && styles.linkListDark
    )}>
      {title && <Title
        className={styles.title}
        size={TitleSize.Medium}
        title={title}
      />}
      {subtitle}
      <div className={styles.linkList}>
        {items?.map((item: ButtonProps, index: number) => (
          <Button
            key={`link-${index}-${item.title}`}
            href={item.href}
            onClick={item.onClick}
            variant={variant}
            title={item.title}
            imageBefore={item.imageBefore}
            imageBeforePlaceholder={item.imageBeforePlaceholder}
            iconBefore={item.iconBefore}
            iconAfter={iconAfter as Icons}
            size={buttonSize as ButtonSize}
            darkMode={darkMode}
          >
          {item.title}
          </Button>
        ))}
      </div>
    </div>
  )
}

export default LinkList

export type {
  LinkListProps,
  LinkListComponentProps
}

export {
  LinkListDirection,
  LinkListLayout
}
