import type { FunctionComponent, PropsWithChildren } from 'react'
import type { Icons } from '../../design-tokens/iconography/icons'

import clsx from 'clsx'

import Icon from '../icon'

import styles from './meta.module.scss'

enum MetaWeight {
  Medium = 'Medium',
  Bold = 'Bold'
}

interface MetaProps extends PropsWithChildren {
  iconBefore?: Icons
  weight?: string | MetaWeight
  className?: string
  darkMode?: boolean
}

const Meta: FunctionComponent<MetaProps> = (
  {
    iconBefore,
    weight,
    children,
    className,
    darkMode
  }
) => {
  const TagElement = 'span'

  return <TagElement
    className={clsx(
      styles.meta,
      weight === MetaWeight.Medium && styles.metaMedium,
      weight === MetaWeight.Bold && styles.metaBold,
      className,
      darkMode && styles.metaDark
    )}
  >
    {iconBefore && <span className={styles.iconBefore}><Icon name={iconBefore}/></span>}
    {children}
  </TagElement>
}

export default Meta

export type {
  MetaProps
}

export {
  MetaWeight
}
