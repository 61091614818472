import type { FunctionComponent } from 'react'
import type { StoryCardComponentProps } from '../../story-card'

import StoryCard, { StoryCardLayout } from '../../story-card'

import styles from './articles.module.scss'

interface SpotlightArticlesProps {
  items: StoryCardComponentProps[]
  activeIndex: number,
  ebaData: any
}

const SpotlightArticles: FunctionComponent<SpotlightArticlesProps> = (
  {
    items,
    activeIndex = 0,
    ebaData = {}
  }
) => {
  return <section className={styles.wrapper} data-active={`${activeIndex}`}>
    <div className={styles.articles}>
      {items.map((item: StoryCardComponentProps, index: number) =>
        <StoryCard
          layout={StoryCardLayout.Vertical}
          desktopLayout={StoryCardLayout.Vertical}
          titleSize={'Medium'}
          key={`${index}-${item.componentProps.title}`}
          label={item.componentProps.label}
          title={item.componentProps.title}
          text={item.componentProps.text}
          link={item.componentProps.link}
          ebaData={{
            listElementPosition: index + 1,
            elementTargetUrl: item.componentProps.link,
            elementTitle: item.componentProps.title,
            ...ebaData
          }}
        />
      )}
    </div>
  </section>
}

export default SpotlightArticles
