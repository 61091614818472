import type { MediaCardVerticalProps } from './vertical'

import clsx from 'clsx'
import { FunctionComponent, useState } from 'react'

import Icon from '../../../atoms/icon'
import Title, { TitleSize } from '../../../atoms/title'
import { Avatar } from '../../../molecules/avatar'
import { Carousel } from '../../../molecules/carousel'
import Image, { ImageLayout } from '../../../molecules/image'
import Pill from '../../../molecules/pill'
import Card from '../../card'
import styles from '../media-card-story.module.scss'

type MediaCardPlaylistComponent = {
  componentProps: MediaCardPlaylist
}

type MediaCardPlaylist = {
  posterImageUrls: string[]
}

interface MediaCardStoryProps extends MediaCardVerticalProps {
  playlist: MediaCardPlaylistComponent,
  mediumAmount?: number
}

const MediaCardStory: FunctionComponent<MediaCardStoryProps> = (
  {
    link,
    label,
    target,
    title,
    playlist,
    onClick,
    className,
    darkMode,
    iconBefore,
    innerRef,
    mediumAmount = 6
  }
) => {
  const [ activeIndex, setActiveIndex ] = useState(0)

  const items = playlist?.componentProps?.posterImageUrls
  //   ?.concat([
  //   '//images-stag.vrt.be/orig/2024/04/16/405e17c1-fbd8-11ee-b2d3-024847ac1f65.jpg',
  //   '//images-stag.vrt.be/orig/2024/04/16/3f9ff7e0-fbd8-11ee-b2d3-024847ac1f65.jpg',
  //   '//images-stag.vrt.be/orig/2024/04/16/3f9ff7e0-fbd8-11ee-b2d3-024847ac1f65.jpg',
  //   '//images-stag.vrt.be/orig/2024/04/16/3f9ff7e0-fbd8-11ee-b2d3-024847ac1f65.jpg',
  //   '//images-stag.vrt.be/orig/2024/04/16/405e17c1-fbd8-11ee-b2d3-024847ac1f65.jpg'
  // ])
  //   .slice(0,4)

  return <Card
    link={link}
    target={target}
    className={clsx(
      styles.mediaCardStory,
      darkMode && styles.dark,
      className
    )}
    darkMode={darkMode}
    innerRef={innerRef}
  >
    <div
      className={clsx(
        styles.mediaCardContent,
        styles.clickable,
        activeIndex > 0 && styles.hide
      )}
      onClick={onClick}
    >
      {
        label
        && <Pill
          className={styles.mediaCount}
          {...label}
        />
      }
      {title && <Title size={TitleSize.Small}>{title}</Title>}
      {
        iconBefore
        && <Avatar icon={iconBefore.name} className={styles.icon} />
      }
    </div>
    <div className={styles.mediaCardCarousel}>
      <Carousel
        enablePagination={false}
        slidesOffsetBefore={140}
        slidesOffsetAfter={20}
        slidesPerView={2.5}
        slidesPerGroup={1}
        slidesPerViewMedium={mediumAmount}
        slidesPerGroupMedium={1}
        slidesOffsetBeforeMedium={items.length >= mediumAmount ? 190 : 0}
        slidesOffsetAfterMedium={items.length >= mediumAmount ? 20 : 0}
        className={items.length < mediumAmount ? styles.alignRight : undefined}
        onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
      >
        {items.map((posterImageUrl, index) => (
          <div
            key={`${index}-${posterImageUrl}`}
            className={clsx(
              styles.mediaCardCarouselItem,
              styles.clickable
            )}
            onClick={() => onClick && onClick(index)}
          >
            <Image
              src={posterImageUrl}
              alt={title}
              layout={ImageLayout.Portrait}
            />
            <Icon name="channels-sporza-livestream-alt" darkMode={true} className={styles.carouselIcon} />
          </div>
        ))}
      </Carousel>
    </div>
  </Card>
}

export {
  MediaCardStory
}

export type {
  MediaCardStoryProps,
}
