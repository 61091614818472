import type { FunctionComponent } from 'react'

import clsx from 'clsx'

import Caption from '../../../atoms/caption'
import Title, { TitleSize } from '../../../atoms/title'
import Image from '../../../molecules/image'
import Pill from '../../../molecules/pill'

import styles from './custom.cell.module.scss'

interface CellWithSubTitleProps {
  title: string,
  subTitle: string,
  image?: string,
  isActive?: boolean,
  isLive?: boolean
  href?: string
}

const CellWithSubTitle: FunctionComponent<CellWithSubTitleProps> = (
  {
    title,
    subTitle,
    image,
    isActive,
    isLive,
    href
  }
) => {
  const TagElement = href ? 'a' : 'div'

  return <TagElement
    href={href}
    className={clsx(
    styles.subTitleWrapper,
    isActive && styles.active,
    isLive && styles.live
  )}>
    {image && <div className={styles.image}>
      <Image src={image} alt={''}/>
    </div>}
    <div className={styles.subTitle}>
      <Title size={TitleSize.XSmall}>
        {title}
        {isLive && <Pill>nu live</Pill>}
      </Title>
      <Caption>
        {subTitle}
      </Caption>
    </div>
  </TagElement>
}

export default CellWithSubTitle
