import type { FunctionComponent } from 'react'

import clsx from 'clsx'

import { StatusTypes } from '../score'

import EventSet, { EventSetProps } from './event-set'
import styles from './field-timeline.module.scss'
import Ruler, { RulerItemProps } from './ruler'

interface FieldTimelineProps {
  status: StatusTypes
  eventSets: EventSetProps[]
  ruler?: RulerItemProps[]
}

const FieldTimeline: FunctionComponent<FieldTimelineProps> = ({
  status,
  eventSets,
  ruler
}) => {
  return <div className={clsx(
    status === StatusTypes.Live && styles.live,
    styles.fieldTimeline
  )}>
    {eventSets?.length > 0 && eventSets.map((item, index) =>
      <EventSet key={`${index}-event`} {...item} />
    )}
    {ruler && ruler?.length > 0 && <Ruler items={ruler} />}
  </div>
}

export default FieldTimeline

export type {
  FieldTimelineProps
}
