import type { FunctionComponent } from 'react'
import type { ColumnDetails } from '../layouts/table'
import type { HeaderGroup } from '@tanstack/react-table'

import { flexRender } from '@tanstack/react-table'
import { pascalCase } from 'change-case'
import clsx from 'clsx'

import styles from '../layouts/table.module.scss'

interface HeaderRowProps {
  headerGroup : HeaderGroup<ColumnDetails>
}

const HeaderRow: FunctionComponent<HeaderRowProps> = ({ headerGroup }) => {

  return <tr>
    {
      headerGroup
        .headers
        .map((header: any) => {
          return !header?.column?.columnDef?.hideHeader && <th
            key={header.id}
            className={
              clsx(
                styles.cellHead,
                header.column.getIsPinned() && styles.cellSticky,
                header?.column?.columnDef?.classNames?.map((className: string) => styles[`cell${pascalCase(className)}`]),
                header?.column?.columnDef?.typeOptions?.abbr && styles.cellAbbr
              )
            }
            colSpan={header.column.columnDef?.colSpan}
            style={{
              left: header.column.getStart('left'),
              right: header.column.getStart('right'),
              textAlign: header?.column?.columnDef?.typeOptions?.align ? header?.column?.columnDef?.typeOptions?.align : undefined
            }}
          >
            {
              header.isPlaceholder
                ? null
                : flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )
            }
          </th>
        })
    }
  </tr>
}
export default HeaderRow
